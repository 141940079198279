exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-charts-all-color-names-js": () => import("./../../../src/pages/charts/all-color-names.js" /* webpackChunkName: "component---src-pages-charts-all-color-names-js" */),
  "component---src-pages-charts-css-colors-names-chart-js": () => import("./../../../src/pages/charts/css-colors-names-chart.js" /* webpackChunkName: "component---src-pages-charts-css-colors-names-chart-js" */),
  "component---src-pages-charts-flat-design-colors-chart-js": () => import("./../../../src/pages/charts/flat-design-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-flat-design-colors-chart-js" */),
  "component---src-pages-charts-index-js": () => import("./../../../src/pages/charts/index.js" /* webpackChunkName: "component---src-pages-charts-index-js" */),
  "component---src-pages-charts-material-design-colors-chart-js": () => import("./../../../src/pages/charts/material-design-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-material-design-colors-chart-js" */),
  "component---src-pages-charts-motip-colors-js": () => import("./../../../src/pages/charts/motip-colors.js" /* webpackChunkName: "component---src-pages-charts-motip-colors-js" */),
  "component---src-pages-charts-ncs-colors-chart-js": () => import("./../../../src/pages/charts/ncs-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-ncs-colors-chart-js" */),
  "component---src-pages-charts-ntc-colors-chart-js": () => import("./../../../src/pages/charts/ntc-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-ntc-colors-chart-js" */),
  "component---src-pages-charts-pantone-colors-chart-js": () => import("./../../../src/pages/charts/pantone-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-pantone-colors-chart-js" */),
  "component---src-pages-charts-ral-classic-colors-chart-js": () => import("./../../../src/pages/charts/ral-classic-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-ral-classic-colors-chart-js" */),
  "component---src-pages-charts-ral-design-plus-colors-chart-js": () => import("./../../../src/pages/charts/ral-design-plus-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-ral-design-plus-colors-chart-js" */),
  "component---src-pages-charts-ral-effect-colors-chart-js": () => import("./../../../src/pages/charts/ral-effect-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-ral-effect-colors-chart-js" */),
  "component---src-pages-charts-websafe-colors-chart-js": () => import("./../../../src/pages/charts/websafe-colors-chart.js" /* webpackChunkName: "component---src-pages-charts-websafe-colors-chart-js" */),
  "component---src-pages-color-converter-index-js": () => import("./../../../src/pages/color-converter/index.js" /* webpackChunkName: "component---src-pages-color-converter-index-js" */),
  "component---src-pages-color-palettes-index-js": () => import("./../../../src/pages/color-palettes/index.js" /* webpackChunkName: "component---src-pages-color-palettes-index-js" */),
  "component---src-pages-color-palettes-my-palettes-js": () => import("./../../../src/pages/color-palettes/my-palettes.js" /* webpackChunkName: "component---src-pages-color-palettes-my-palettes-js" */),
  "component---src-pages-color-palettes-upload-js": () => import("./../../../src/pages/color-palettes/upload.js" /* webpackChunkName: "component---src-pages-color-palettes-upload-js" */),
  "component---src-pages-color-picker-index-js": () => import("./../../../src/pages/color-picker/index.js" /* webpackChunkName: "component---src-pages-color-picker-index-js" */),
  "component---src-pages-convert-cmyk-to-hex-js": () => import("./../../../src/pages/convert/cmyk-to-hex.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-hex-js" */),
  "component---src-pages-convert-cmyk-to-hsb-js": () => import("./../../../src/pages/convert/cmyk-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-hsb-js" */),
  "component---src-pages-convert-cmyk-to-hsl-js": () => import("./../../../src/pages/convert/cmyk-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-hsl-js" */),
  "component---src-pages-convert-cmyk-to-lab-js": () => import("./../../../src/pages/convert/cmyk-to-lab.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-lab-js" */),
  "component---src-pages-convert-cmyk-to-ncs-js": () => import("./../../../src/pages/convert/cmyk-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-ncs-js" */),
  "component---src-pages-convert-cmyk-to-pantone-js": () => import("./../../../src/pages/convert/cmyk-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-pantone-js" */),
  "component---src-pages-convert-cmyk-to-ral-design-js": () => import("./../../../src/pages/convert/cmyk-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-ral-design-js" */),
  "component---src-pages-convert-cmyk-to-ral-effect-js": () => import("./../../../src/pages/convert/cmyk-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-ral-effect-js" */),
  "component---src-pages-convert-cmyk-to-ral-js": () => import("./../../../src/pages/convert/cmyk-to-ral.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-ral-js" */),
  "component---src-pages-convert-cmyk-to-rgb-js": () => import("./../../../src/pages/convert/cmyk-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-rgb-js" */),
  "component---src-pages-convert-cmyk-to-websafe-js": () => import("./../../../src/pages/convert/cmyk-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-cmyk-to-websafe-js" */),
  "component---src-pages-convert-hex-to-cmyk-js": () => import("./../../../src/pages/convert/hex-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-hex-to-cmyk-js" */),
  "component---src-pages-convert-hex-to-hsb-js": () => import("./../../../src/pages/convert/hex-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-hex-to-hsb-js" */),
  "component---src-pages-convert-hex-to-hsl-js": () => import("./../../../src/pages/convert/hex-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-hex-to-hsl-js" */),
  "component---src-pages-convert-hex-to-lab-js": () => import("./../../../src/pages/convert/hex-to-lab.js" /* webpackChunkName: "component---src-pages-convert-hex-to-lab-js" */),
  "component---src-pages-convert-hex-to-ncs-js": () => import("./../../../src/pages/convert/hex-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-hex-to-ncs-js" */),
  "component---src-pages-convert-hex-to-pantone-js": () => import("./../../../src/pages/convert/hex-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-hex-to-pantone-js" */),
  "component---src-pages-convert-hex-to-ral-design-js": () => import("./../../../src/pages/convert/hex-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-hex-to-ral-design-js" */),
  "component---src-pages-convert-hex-to-ral-effect-js": () => import("./../../../src/pages/convert/hex-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-hex-to-ral-effect-js" */),
  "component---src-pages-convert-hex-to-ral-js": () => import("./../../../src/pages/convert/hex-to-ral.js" /* webpackChunkName: "component---src-pages-convert-hex-to-ral-js" */),
  "component---src-pages-convert-hex-to-rgb-js": () => import("./../../../src/pages/convert/hex-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-hex-to-rgb-js" */),
  "component---src-pages-convert-hex-to-websafe-js": () => import("./../../../src/pages/convert/hex-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-hex-to-websafe-js" */),
  "component---src-pages-convert-hsl-to-cmyk-js": () => import("./../../../src/pages/convert/hsl-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-cmyk-js" */),
  "component---src-pages-convert-hsl-to-hex-js": () => import("./../../../src/pages/convert/hsl-to-hex.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-hex-js" */),
  "component---src-pages-convert-hsl-to-hsb-js": () => import("./../../../src/pages/convert/hsl-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-hsb-js" */),
  "component---src-pages-convert-hsl-to-lab-js": () => import("./../../../src/pages/convert/hsl-to-lab.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-lab-js" */),
  "component---src-pages-convert-hsl-to-ncs-js": () => import("./../../../src/pages/convert/hsl-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-ncs-js" */),
  "component---src-pages-convert-hsl-to-pantone-js": () => import("./../../../src/pages/convert/hsl-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-pantone-js" */),
  "component---src-pages-convert-hsl-to-ral-design-js": () => import("./../../../src/pages/convert/hsl-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-ral-design-js" */),
  "component---src-pages-convert-hsl-to-ral-effect-js": () => import("./../../../src/pages/convert/hsl-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-ral-effect-js" */),
  "component---src-pages-convert-hsl-to-ral-js": () => import("./../../../src/pages/convert/hsl-to-ral.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-ral-js" */),
  "component---src-pages-convert-hsl-to-rgb-js": () => import("./../../../src/pages/convert/hsl-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-rgb-js" */),
  "component---src-pages-convert-hsl-to-websafe-js": () => import("./../../../src/pages/convert/hsl-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-hsl-to-websafe-js" */),
  "component---src-pages-convert-index-js": () => import("./../../../src/pages/convert/index.js" /* webpackChunkName: "component---src-pages-convert-index-js" */),
  "component---src-pages-convert-lab-to-cmyk-js": () => import("./../../../src/pages/convert/lab-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-lab-to-cmyk-js" */),
  "component---src-pages-convert-lab-to-hex-js": () => import("./../../../src/pages/convert/lab-to-hex.js" /* webpackChunkName: "component---src-pages-convert-lab-to-hex-js" */),
  "component---src-pages-convert-lab-to-hsb-js": () => import("./../../../src/pages/convert/lab-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-lab-to-hsb-js" */),
  "component---src-pages-convert-lab-to-hsl-js": () => import("./../../../src/pages/convert/lab-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-lab-to-hsl-js" */),
  "component---src-pages-convert-lab-to-ncs-js": () => import("./../../../src/pages/convert/lab-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-lab-to-ncs-js" */),
  "component---src-pages-convert-lab-to-pantone-js": () => import("./../../../src/pages/convert/lab-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-lab-to-pantone-js" */),
  "component---src-pages-convert-lab-to-ral-design-js": () => import("./../../../src/pages/convert/lab-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-lab-to-ral-design-js" */),
  "component---src-pages-convert-lab-to-ral-effect-js": () => import("./../../../src/pages/convert/lab-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-lab-to-ral-effect-js" */),
  "component---src-pages-convert-lab-to-ral-js": () => import("./../../../src/pages/convert/lab-to-ral.js" /* webpackChunkName: "component---src-pages-convert-lab-to-ral-js" */),
  "component---src-pages-convert-lab-to-rgb-js": () => import("./../../../src/pages/convert/lab-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-lab-to-rgb-js" */),
  "component---src-pages-convert-lab-to-websafe-js": () => import("./../../../src/pages/convert/lab-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-lab-to-websafe-js" */),
  "component---src-pages-convert-ncs-to-cmyk-js": () => import("./../../../src/pages/convert/ncs-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-cmyk-js" */),
  "component---src-pages-convert-ncs-to-hex-js": () => import("./../../../src/pages/convert/ncs-to-hex.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-hex-js" */),
  "component---src-pages-convert-ncs-to-hsb-js": () => import("./../../../src/pages/convert/ncs-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-hsb-js" */),
  "component---src-pages-convert-ncs-to-hsl-js": () => import("./../../../src/pages/convert/ncs-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-hsl-js" */),
  "component---src-pages-convert-ncs-to-lab-js": () => import("./../../../src/pages/convert/ncs-to-lab.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-lab-js" */),
  "component---src-pages-convert-ncs-to-pantone-js": () => import("./../../../src/pages/convert/ncs-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-pantone-js" */),
  "component---src-pages-convert-ncs-to-ral-design-js": () => import("./../../../src/pages/convert/ncs-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-ral-design-js" */),
  "component---src-pages-convert-ncs-to-ral-effect-js": () => import("./../../../src/pages/convert/ncs-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-ral-effect-js" */),
  "component---src-pages-convert-ncs-to-ral-js": () => import("./../../../src/pages/convert/ncs-to-ral.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-ral-js" */),
  "component---src-pages-convert-ncs-to-rgb-js": () => import("./../../../src/pages/convert/ncs-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-rgb-js" */),
  "component---src-pages-convert-ncs-to-websafe-js": () => import("./../../../src/pages/convert/ncs-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-ncs-to-websafe-js" */),
  "component---src-pages-convert-pantone-to-cmyk-js": () => import("./../../../src/pages/convert/pantone-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-cmyk-js" */),
  "component---src-pages-convert-pantone-to-hex-js": () => import("./../../../src/pages/convert/pantone-to-hex.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-hex-js" */),
  "component---src-pages-convert-pantone-to-hsb-js": () => import("./../../../src/pages/convert/pantone-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-hsb-js" */),
  "component---src-pages-convert-pantone-to-hsl-js": () => import("./../../../src/pages/convert/pantone-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-hsl-js" */),
  "component---src-pages-convert-pantone-to-lab-js": () => import("./../../../src/pages/convert/pantone-to-lab.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-lab-js" */),
  "component---src-pages-convert-pantone-to-ncs-js": () => import("./../../../src/pages/convert/pantone-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-ncs-js" */),
  "component---src-pages-convert-pantone-to-ral-design-js": () => import("./../../../src/pages/convert/pantone-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-ral-design-js" */),
  "component---src-pages-convert-pantone-to-ral-effect-js": () => import("./../../../src/pages/convert/pantone-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-ral-effect-js" */),
  "component---src-pages-convert-pantone-to-ral-js": () => import("./../../../src/pages/convert/pantone-to-ral.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-ral-js" */),
  "component---src-pages-convert-pantone-to-rgb-js": () => import("./../../../src/pages/convert/pantone-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-rgb-js" */),
  "component---src-pages-convert-pantone-to-websafe-js": () => import("./../../../src/pages/convert/pantone-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-pantone-to-websafe-js" */),
  "component---src-pages-convert-ral-design-to-cmyk-js": () => import("./../../../src/pages/convert/ral-design-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-cmyk-js" */),
  "component---src-pages-convert-ral-design-to-hex-js": () => import("./../../../src/pages/convert/ral-design-to-hex.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-hex-js" */),
  "component---src-pages-convert-ral-design-to-hsb-js": () => import("./../../../src/pages/convert/ral-design-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-hsb-js" */),
  "component---src-pages-convert-ral-design-to-hsl-js": () => import("./../../../src/pages/convert/ral-design-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-hsl-js" */),
  "component---src-pages-convert-ral-design-to-lab-js": () => import("./../../../src/pages/convert/ral-design-to-lab.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-lab-js" */),
  "component---src-pages-convert-ral-design-to-ncs-js": () => import("./../../../src/pages/convert/ral-design-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-ncs-js" */),
  "component---src-pages-convert-ral-design-to-pantone-js": () => import("./../../../src/pages/convert/ral-design-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-pantone-js" */),
  "component---src-pages-convert-ral-design-to-ral-effect-js": () => import("./../../../src/pages/convert/ral-design-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-ral-effect-js" */),
  "component---src-pages-convert-ral-design-to-ral-js": () => import("./../../../src/pages/convert/ral-design-to-ral.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-ral-js" */),
  "component---src-pages-convert-ral-design-to-rgb-js": () => import("./../../../src/pages/convert/ral-design-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-rgb-js" */),
  "component---src-pages-convert-ral-design-to-websafe-js": () => import("./../../../src/pages/convert/ral-design-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-ral-design-to-websafe-js" */),
  "component---src-pages-convert-ral-effect-to-cmyk-js": () => import("./../../../src/pages/convert/ral-effect-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-cmyk-js" */),
  "component---src-pages-convert-ral-effect-to-hex-js": () => import("./../../../src/pages/convert/ral-effect-to-hex.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-hex-js" */),
  "component---src-pages-convert-ral-effect-to-hsb-js": () => import("./../../../src/pages/convert/ral-effect-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-hsb-js" */),
  "component---src-pages-convert-ral-effect-to-hsl-js": () => import("./../../../src/pages/convert/ral-effect-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-hsl-js" */),
  "component---src-pages-convert-ral-effect-to-lab-js": () => import("./../../../src/pages/convert/ral-effect-to-lab.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-lab-js" */),
  "component---src-pages-convert-ral-effect-to-ncs-js": () => import("./../../../src/pages/convert/ral-effect-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-ncs-js" */),
  "component---src-pages-convert-ral-effect-to-pantone-js": () => import("./../../../src/pages/convert/ral-effect-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-pantone-js" */),
  "component---src-pages-convert-ral-effect-to-ral-effect-design-js": () => import("./../../../src/pages/convert/ral-effect-to-ral-effect-design.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-ral-effect-design-js" */),
  "component---src-pages-convert-ral-effect-to-ral-js": () => import("./../../../src/pages/convert/ral-effect-to-ral.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-ral-js" */),
  "component---src-pages-convert-ral-effect-to-rgb-js": () => import("./../../../src/pages/convert/ral-effect-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-rgb-js" */),
  "component---src-pages-convert-ral-effect-to-websafe-js": () => import("./../../../src/pages/convert/ral-effect-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-ral-effect-to-websafe-js" */),
  "component---src-pages-convert-ral-to-cmyk-js": () => import("./../../../src/pages/convert/ral-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-ral-to-cmyk-js" */),
  "component---src-pages-convert-ral-to-hex-js": () => import("./../../../src/pages/convert/ral-to-hex.js" /* webpackChunkName: "component---src-pages-convert-ral-to-hex-js" */),
  "component---src-pages-convert-ral-to-hsb-js": () => import("./../../../src/pages/convert/ral-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-ral-to-hsb-js" */),
  "component---src-pages-convert-ral-to-hsl-js": () => import("./../../../src/pages/convert/ral-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-ral-to-hsl-js" */),
  "component---src-pages-convert-ral-to-lab-js": () => import("./../../../src/pages/convert/ral-to-lab.js" /* webpackChunkName: "component---src-pages-convert-ral-to-lab-js" */),
  "component---src-pages-convert-ral-to-ncs-js": () => import("./../../../src/pages/convert/ral-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-ral-to-ncs-js" */),
  "component---src-pages-convert-ral-to-pantone-js": () => import("./../../../src/pages/convert/ral-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-ral-to-pantone-js" */),
  "component---src-pages-convert-ral-to-ral-design-js": () => import("./../../../src/pages/convert/ral-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-ral-to-ral-design-js" */),
  "component---src-pages-convert-ral-to-ral-effect-js": () => import("./../../../src/pages/convert/ral-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-ral-to-ral-effect-js" */),
  "component---src-pages-convert-ral-to-rgb-js": () => import("./../../../src/pages/convert/ral-to-rgb.js" /* webpackChunkName: "component---src-pages-convert-ral-to-rgb-js" */),
  "component---src-pages-convert-ral-to-websafe-js": () => import("./../../../src/pages/convert/ral-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-ral-to-websafe-js" */),
  "component---src-pages-convert-rgb-to-cmyk-js": () => import("./../../../src/pages/convert/rgb-to-cmyk.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-cmyk-js" */),
  "component---src-pages-convert-rgb-to-hex-js": () => import("./../../../src/pages/convert/rgb-to-hex.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-hex-js" */),
  "component---src-pages-convert-rgb-to-hsb-js": () => import("./../../../src/pages/convert/rgb-to-hsb.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-hsb-js" */),
  "component---src-pages-convert-rgb-to-hsl-js": () => import("./../../../src/pages/convert/rgb-to-hsl.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-hsl-js" */),
  "component---src-pages-convert-rgb-to-lab-js": () => import("./../../../src/pages/convert/rgb-to-lab.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-lab-js" */),
  "component---src-pages-convert-rgb-to-ncs-js": () => import("./../../../src/pages/convert/rgb-to-ncs.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-ncs-js" */),
  "component---src-pages-convert-rgb-to-pantone-js": () => import("./../../../src/pages/convert/rgb-to-pantone.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-pantone-js" */),
  "component---src-pages-convert-rgb-to-ral-design-js": () => import("./../../../src/pages/convert/rgb-to-ral-design.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-ral-design-js" */),
  "component---src-pages-convert-rgb-to-ral-effect-js": () => import("./../../../src/pages/convert/rgb-to-ral-effect.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-ral-effect-js" */),
  "component---src-pages-convert-rgb-to-ral-js": () => import("./../../../src/pages/convert/rgb-to-ral.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-ral-js" */),
  "component---src-pages-convert-rgb-to-websafe-js": () => import("./../../../src/pages/convert/rgb-to-websafe.js" /* webpackChunkName: "component---src-pages-convert-rgb-to-websafe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tools-image-color-palette-js": () => import("./../../../src/pages/tools/image-color-palette.js" /* webpackChunkName: "component---src-pages-tools-image-color-palette-js" */),
  "component---src-templates-color-palettes-js": () => import("./../../../src/templates/colorPalettes.js" /* webpackChunkName: "component---src-templates-color-palettes-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

